import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';

import { TypesService } from '../../types.service';
import { OptionSetHierarchiesService } from './option-set-hierarchies.service';

@Injectable()
export class OptionSetHierarchiesEffects {
  constructor(
    private actions$: Actions,
    private optionSetHierarchyService: OptionSetHierarchiesService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadOptionSetHierarchies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.OptionSetHierarchiesActionTypes.LOAD_OPTION_SET_HIERARCHIES),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.optionSetHierarchyService.get()).pipe(
          map((data) => TypeManagerActions.loadOptionSetHierarchiesSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadOptionSetHierarchiesFailure({ error }))),
        ),
      ),
    ),
  );
  createOptionSetHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.OptionSetHierarchiesActionTypes.CREATE_OPTION_SET_HIERARCHY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.optionSetHierarchyService.create(action.optionSetHierarchy)).pipe(
          map((data) => {
            this.snackBar.open('Option Hierarchy created.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.setCurrentOptionSetHierarchy({ currentOptionSetHierarchy: data }));
            return TypeManagerActions.createOptionSetHierarchySuccess({ optionSetHierarchy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createOptionSetHierarchyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteOptionSetHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.OptionSetHierarchiesActionTypes.DELETE_OPTION_SET_HIERARCHY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.optionSetHierarchyService.delete(action.optionSetHierarchy)).pipe(
          map((data) => {
            this.snackBar.open('Option Hierarchy deleted.', '', { duration: 2000 });
            return TypeManagerActions.deleteOptionSetHierarchySuccess({ optionSetHierarchy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteOptionSetHierarchyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateOptionSetHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.OptionSetHierarchiesActionTypes.UPDATE_OPTION_SET_HIERARCHY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.optionSetHierarchyService.update(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Option Hierarchy updated.', '', { duration: 2000 });
            return TypeManagerActions.updateOptionSetHierarchySuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.updateOptionSetHierarchyFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
