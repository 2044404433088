import { Injectable } from '@angular/core';
import { CONFIG_VIEW } from './types-helper';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TypeService {
  private toggleConfigView = new Subject<CONFIG_VIEW>();
  private addPolicy = new Subject<void>();
  private isDetailViewOpen = new Subject<boolean>();

  constructor() {}

  toggleConfigVisibility(view: CONFIG_VIEW): void {
    this.toggleConfigView.next(view);
  }

  getConfigVisibility(): Subject<CONFIG_VIEW> {
    return this.toggleConfigView;
  }

  addPolicyStatement(): void {
    this.addPolicy.next();
  }

  getAddPolicyStatement(): Subject<void> {
    return this.addPolicy;
  }

  toggleDetailView(open: boolean): void {
    this.isDetailViewOpen.next(open);
  }

  getDetailView(): Subject<boolean> {
    return this.isDetailViewOpen;
  }
}
