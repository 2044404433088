<div class="modal">
  <div class="modal-content justify-between">
    <div class="flex justify-between">
      <h6>Import {{ importType | lowercase | titlecase | replace: '_' : ' ' }}</h6>
      <mat-icon [mat-dialog-close]="false" (click)="closeModal()" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
    </div>
    <div *ngIf="importType === 'OPTION_SET_HIERARCHY'" class="m-4">
      <mat-radio-group (change)="onRadioChange($event.value)">
        <mat-radio-button value="keys" checked data-test="keys-radio-button">CSV with Keys</mat-radio-button>
        <mat-radio-button value="values" class="ml-4" data-test="values-radio-button">CSV with Values</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="flex justify-center w-full mt-2 mb-2">
      <div class="drop-zone">
        <div class="flex flex-col justify-center items-center pt-5 mt-2">
          <mat-icon class="upload-icon"> upload </mat-icon>
          <div class="upload-container mt-2 pt-2">
            Drag a CSV here or
            <span class="underline cursor-pointer mx-1" (click)="fileUpload.click()">select</span> from your computer
          </div>
          <div class="flex justify-center mt-2">
            <img src="assets/images/{{ importType.toLowerCase() }}-import-eg.png" class="w-10/12 rounded" />
          </div>
          <input #fileUpload type="file" style="display: none" (change)="onFileSelected($event, fileUpload)" />
        </div>
      </div>
    </div>
    <div *ngIf="currentFile && !isLoading && !successMessage">
      <p class="mt-3"><span class="font-semibold">Selected File:</span> {{ currentFile.name }}</p>
    </div>

    <div *ngIf="errors.length > 0" class="flex flex-row justify-center items-center">
      <mat-icon class="icon items-center error-icon">error</mat-icon>
      <h6 class="">Errors:</h6>
    </div>

    <div *ngIf="errors.length > 0" class="error-container">
      <div
        *ngFor="let error of errors; let i = index"
        class="flex items-center justify-between rounded border-1 border-gray-200 p-1 my-1 cursor-pointer hover:border-blue-300"
      >
        <div class="flex items-end">
          <ul class="flex flex-col ml-1">
            <li data-test="error-message" class="font-semibold">{{ i + 1 }}. {{ error }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading && errors.length === 0; else errorWarningBlock">
      <div class="spinner-bg">
        <mat-spinner class="spinner" diameter="40"></mat-spinner>
      </div>
    </div>

    <ng-template #errorWarningBlock>
      <div *ngIf="errorMessage" class="flex flex-row justify-center items-center mt-2">
        <mat-icon class="icon items-center error-icon">error</mat-icon>
        <h6 class="">Error</h6>
      </div>
      <div *ngIf="errorMessage" class="error-container">
        <div
          class="flex justify-center items-center rounded border-1 border-gray-200 p-1 my-1 cursor-pointer hover:border-blue-300"
        >
          <div class="flex">
            <ul class="flex flex-col ml-1">
              <li data-test="error-message" class="font-semibold">{{ errorMessage }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="warningMessage && !errorMessage" class="flex flex-row justify-center items-center mt-2">
        <mat-icon class="icon items-center warning-icon">warning</mat-icon>
        <h6 class="">Warning</h6>
      </div>
      <div *ngIf="warningMessage" class="error-container">
        <div
          class="flex justify-center items-center rounded border-1 border-gray-200 p-1 my-1 cursor-pointer hover:border-blue-300"
        >
          <div class="flex">
            <ul class="flex flex-col ml-1">
              <li class="font-semibold" data-test="warning-message">{{ warningMessage }}</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="flex items-center justify-end mt-3">
      <button mat-stroked-button color="primary" (click)="closeModal()" class="!mr-3">Cancel</button>
      <button
        data-test="import"
        mat-raised-button
        color="primary"
        (click)="handleImport()"
        [disabled]="isLoading || !currentFile || errorMessage"
      >
        Import
      </button>
    </div>
  </div>
</div>
