import { Component } from '@angular/core';
import { TypeProperty, Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';

@Component({
  selector: 'app-type-property-details',
  templateUrl: './type-property-details.component.html',
  styleUrls: ['./type-property-details.component.scss'],
})
export class TypePropertyDetailsComponent {
  public typeProperty$: Observable<TypeProperty>;
  public type$: Observable<Type>;
  constructor(private store: Store<RootStoreState.State>) {
    this.typeProperty$ = this.store.select(TypeManagerSelectors.currentTypeProperty);
    this.type$ = this.store.select(TypeManagerSelectors.currentType);
  }

  handleChange(changes) {
    if (changes?.defaultValue) {
      changes.defaultValue = String(changes.defaultValue);
    }
    let typeProperty;
    this.typeProperty$
      .pipe(
        take(1),
        tap((property) => (typeProperty = property)),
      )
      .subscribe();

    this.store.dispatch(
      TypeManagerActions.updateTypeProperty({
        id: typeProperty.id,
        changes: changes,
      }),
    );
  }
}
