import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';

import { TypesService } from '../../types.service';
import { TypePoliciesService } from './type-policies.service';

@Injectable()
export class TypePoliciesEffects {
  constructor(
    private actions$: Actions,
    private typePolicyService: TypePoliciesService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadTypePolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePoliciesActionTypes.LOAD_TYPE_POLICIES),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.typePolicyService.get()).pipe(
          map((data) => {
            this.store.dispatch(TypeManagerActions.loading({ loading: false }));
            return TypeManagerActions.loadTypePoliciesSuccess({ data });
          }),
          catchError((error) => {
            this.store.dispatch(TypeManagerActions.loading({ loading: false }));
            return observableOf(TypeManagerActions.loadTypePoliciesFailure({ error }));
          }),
        ),
      ),
    ),
  );
  createTypePolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePoliciesActionTypes.CREATE_TYPE_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePolicyService.create(action.typePolicy)).pipe(
          map((data) => {
            this.snackBar.open('Policy created.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.setCurrentTypePolicy({ currentTypePolicy: data }));
            return TypeManagerActions.createTypePolicySuccess({ typePolicy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createTypePolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteTypePolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePoliciesActionTypes.DELETE_TYPE_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePolicyService.delete(action.typePolicy)).pipe(
          map((data) => {
            this.snackBar.open('Policy deleted.', '', { duration: 2000 });
            return TypeManagerActions.deleteTypePolicySuccess({ typePolicy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteTypePolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateTypePolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePoliciesActionTypes.UPDATE_TYPE_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePolicyService.update(action.id, action.changes)).pipe(
          map((data) => {
            this.store.dispatch(TypeManagerActions.updating({ updating: false }));
            this.snackBar.open('Policy updated.', '', { duration: 2000 });
            return TypeManagerActions.updateTypePolicySuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.store.dispatch(TypeManagerActions.updating({ updating: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.updateTypePolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
