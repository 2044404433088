<app-page-section data-test="policy-statement" class="policy-statement">
  <form [formGroup]="form">
    <app-page-section-body class="policy-container">
      <div class="field-content">
        <mat-form-field class="field" data-test="principal-container">
          <mat-label>Principal</mat-label>
          <mat-select class="dynamic-fields" formControlName="principal" data-test="principal-select">
            <mat-select-trigger class="principal-select-trigger">
              <mat-icon *ngIf="form.value.principal?.type === prinicpalType.GROUP" class="principal-icon">
                people_alt
              </mat-icon>
              <mat-icon *ngIf="form.value.principal?.type === prinicpalType.USER" class="principal-icon">
                person
              </mat-icon>
              <mat-icon *ngIf="form.value.principal?.type === prinicpalType.EVERYONE" class="principal-icon">
                public
              </mat-icon>
              &nbsp;&nbsp;&nbsp; {{ form?.value?.principal?.name?.trim() || form?.value?.principal?.email?.trim() }}
            </mat-select-trigger>

            <mat-option *ngFor="let principal of principals" [value]="principal" data-test="principal-select-option">
              <div>
                <mat-icon *ngIf="principal?.type === prinicpalType.GROUP"> people_alt </mat-icon>
                <mat-icon *ngIf="principal?.type === prinicpalType.USER"> person </mat-icon>
                <mat-icon *ngIf="principal.type === prinicpalType.EVERYONE"> public </mat-icon>
                {{ principal?.name?.trim() || principal?.email?.trim() }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field-content" formArrayName="action">
        <mat-label class="action-label">Actions *</mat-label>
        <div *ngFor="let action of form['controls'].action['controls']; let i = index">
          <ng-container [formGroupName]="i">
            <mat-checkbox color="accent" class="ml-1" formControlName="checked" data-test="policy-action">
              {{ action.value.name | titlecase }}
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
      <div class="field-content mt-5">
        <mat-form-field class="field" data-test="effects-container">
          <mat-label>Effect</mat-label>
          <mat-select class="dynamic-fields" formControlName="effect" data-test="effect-select">
            <mat-option *ngFor="let effect of effects" [value]="effect" data-test="principal-select-option">
              {{ effect | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="delete-container">
        <button
          mat-icon-button
          class="remove-field cursor-pointer"
          matTooltip="Delete Principal"
          data-test="btn-remove-field"
          (click)="handleDelete()"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </app-page-section-body>
  </form>
</app-page-section>
