import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Entities, Types } from '@contrail/sdk';
import { Type, TypeProperty, TypeRoot } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RoutingService } from '../common/routing/routing.service';
import { RootStoreState } from '../root-store';
import { TypeManagerSelectors } from './type-manager-store';

@Injectable({
  providedIn: 'root',
})
export class TypesService {
  public selectedTypeRoot: TypeRoot;
  public selectedType: Type;
  private typeRoots: Array<TypeRoot>;
  constructor(
    public store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private router: Router,
  ) {
    this.store.select(TypeManagerSelectors.typeRoots).subscribe((roots) => (this.typeRoots = roots));
    this.store.select(TypeManagerSelectors.currentType).subscribe((type) => (this.selectedType = type));
    this.store.select(TypeManagerSelectors.currentTypeRoot).subscribe((root) => (this.selectedTypeRoot = root));
  }

  public async getTypesForRoot(root: TypeRoot) {
    return new Entities().get({ entityName: 'type', criteria: { typeRootId: root.id } });
  }
  public async getTypeById(id) {
    return new Types().getType({ id, relations: ['typeProperties'] });
  }

  public async getTypeByTypePath(typePath) {
    return new Types().getType({ path: typePath });
  }

  public getLabelForEntitySlug(slug: string) {
    const root = this.typeRoots.filter((r) => r.slug === slug)[0];
    return root?.label;
  }

  public async addPropertyToType(type: Type, property: TypeProperty) {
    const newProperty = await new Entities().create({
      entityName: 'type',
      id: type.id,
      relation: 'type-properties',
      object: property,
    });
    Types.clearTypeCache();
    return newProperty;
  }
  public async deleteTypeProperty(type: Type, property: TypeProperty) {
    await new Entities().delete({
      entityName: 'type',
      id: type.id,
      relation: 'type-properties',
      relationId: property.id,
    });
    Types.clearTypeCache();
    return property;
  }
  public async createType(type: Type) {
    const newType = await new Entities().create({
      entityName: 'type',
      id: type.id,
      object: type,
    });
    Types.clearTypeCache();
    return newType;
  }

  public goToRoot(rootSlug) {
    this.routingService.go('type-manager/types/' + rootSlug);
  }

  public goToType(id) {
    const currentUrl = this.router.url;
    let base = currentUrl;
    if (currentUrl.indexOf('/type/') > 0) {
      base = currentUrl.slice(0, currentUrl.indexOf('/type/'));
    }
    const route = base + `/type/${id}`;
    this.router.navigate([route]);
  }
}
