import { Component, EventEmitter, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { TypePolicy } from '@contrail/types';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { CONFIG_VIEW, getUniqueId, PolicyStatement, Principal, PRINCIPAL_TYPE } from '../../types-helper';
import { ActivatedRoute } from '@angular/router';
import { TypeService } from '../../type.service';
import { GroupService } from '../../group.service';
import { UserService } from '../../user.service';
import { PolicyDetailComponent } from '../../components/policy-detail/policy-detail.component';
import { Effect } from '@contrail/policies';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-type-policy-editor',
  templateUrl: './type-policy-editor.component.html',
  styleUrls: ['./type-policy-editor.component.scss'],
})
export class TypePolicyEditorComponent implements OnDestroy {
  public policyControl: UntypedFormControl = new UntypedFormControl();
  @Output() public changes = new EventEmitter();

  public editorOptions: any = {
    theme: 'vs',
    language: 'json',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };

  public typePolicy$: Observable<TypePolicy>;
  public typePolicy: TypePolicy;
  public statements: PolicyStatement[] = [];
  public isLoading$: Observable<boolean>;
  public activeConfigView: CONFIG_VIEW = CONFIG_VIEW.UI;
  public view = CONFIG_VIEW;
  public principals$: Observable<Principal[]>;

  private destroy$ = new Subject();

  public updating: boolean = false;

  @ViewChildren(PolicyDetailComponent)
  private policyDetails: QueryList<PolicyDetailComponent>;

  constructor(
    private store: Store<RootStoreState.State>,
    private route: ActivatedRoute,
    private typeService: TypeService,
    private groupService: GroupService,
    private userService: UserService,
  ) {
    this.typePolicy$ = this.store.select(TypeManagerSelectors.currentTypePolicy);
    this.typePolicy$.pipe(takeUntil(this.destroy$)).subscribe((policy) => {
      this.typePolicy = policy;

      this.statements = cloneDeep(this.typePolicy.policy.statements);
      this.statements = this.statements.length ? this.statements : [this.getEmptyStatement()];
      this.statements?.map((statement) => {
        statement.id = getUniqueId();
      });

      this.updateJsonView(this.statements);
    });
  }

  ngOnInit(): void {
    this.store
      .select(TypeManagerSelectors.updating)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updating) => {
        this.updating = updating;
      });

    this.getPrincipals();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.activeConfigView = CONFIG_VIEW.UI;
      const id = params['typePolicyId'];

      if (id) {
        this.typeService.toggleDetailView(true);
      }
    });

    this.typeService
      .getConfigVisibility()
      .pipe(takeUntil(this.destroy$))
      .subscribe((view) => {
        this.activeConfigView = view;

        if (this.activeConfigView === CONFIG_VIEW.JSON) {
          const policyStatements = this.getConfigUIFormValue();
          this.statements = policyStatements;
          const statementWithoutId = policyStatements.map(({ id, ...rest }) => rest);
          this.updateJsonView(statementWithoutId);
        }

        if (this.activeConfigView === CONFIG_VIEW.UI) {
          const policyStatements = JSON.parse(this.policyControl.value);
          this.statements = policyStatements.map((item, index) => ({
            ...item,
            id: getUniqueId(),
          }));
        }
      });
  }

  updateJsonView(statements: PolicyStatement[]) {
    this.policyControl.setValue(JSON.stringify(statements, null, 4));
  }

  getConfigUIFormValue() {
    const policyDetails = this.policyDetails.toArray();
    const policyStatements = [];

    for (let field of policyDetails) {
      const data = field.getPolicyDetail();
      policyStatements.push(data);
    }

    return policyStatements;
  }

  getPrincipals() {
    this.principals$ = combineLatest([this.groupService.getGroups(), this.userService.getUserOrgs()]).pipe(
      map(([groups, userOrgs]) => {
        const principals = groups.map((group) => {
          return {
            id: group.id,
            name: group.name,
            type: PRINCIPAL_TYPE.GROUP,
            email: '',
            reference: `group:${group.id}`,
          };
        });
        userOrgs.map((userOrg) => {
          if (!userOrg?.user) {
            return;
          }

          const name = `${userOrg?.user?.firstName || ''} ${userOrg?.user?.lastName || ''}`;
          principals.push({
            id: userOrg.user.id,
            name: name.trim(),
            type: PRINCIPAL_TYPE.USER,
            email: userOrg.user.email,
            reference: `user:${userOrg.user.id}`,
          });
        });
        return principals;
      }),
    );
  }

  save() {
    this.store.dispatch(TypeManagerActions.updating({ updating: true }));
    const policyValue = ObjectUtil.cloneDeep(this.typePolicy.policy);
    let policyStatements = [];
    if (CONFIG_VIEW.UI === this.activeConfigView) {
      policyStatements = this.getConfigUIFormValue();
    } else {
      policyStatements = JSON.parse(this.policyControl.value);
    }
    policyValue.statements = policyStatements;
    this.store.dispatch(
      TypeManagerActions.updateTypePolicy({ id: this.typePolicy.id, changes: { policy: policyValue } }),
    );
  }

  addField() {
    this.statements.push(this.getEmptyStatement());
  }

  getEmptyStatement() {
    return {
      principal: '*',
      action: [],
      resource: '*',
      effect: Effect.allow,
      id: getUniqueId(),
    } as PolicyStatement;
  }

  deletePolicyStatement(id: string) {
    this.statements = this.statements.filter((statement) => statement.id !== id);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
