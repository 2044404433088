<div *ngIf="typeProperty$ | async" class="fixed-wrap w-full">
  <app-type-property-header [typeProperty]="typeProperty$ | async"></app-type-property-header>
  <app-type-property-core [typeProperty]="typeProperty$ | async"> </app-type-property-core>
  <app-type-property-usage-removal (changes)="handleChange($event)" [typeProperty]="typeProperty$ | async">
  </app-type-property-usage-removal>
  <app-type-property-label [typeProperty]="typeProperty$ | async" (changes)="handleChange($event)">
  </app-type-property-label>
  <app-type-property-level
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
    (changes)="handleChange($event)"
  >
  </app-type-property-level>
  <app-type-property-object-reference [typeProperty]="typeProperty$ | async"></app-type-property-object-reference>
  <!-- <app-type-property-required [typeProperty]="typeProperty$ | async">
  </app-type-property-required> -->

  <app-type-property-options [typeProperty]="typeProperty$ | async"> </app-type-property-options>
  <app-type-property-user-list [typeProperty]="typeProperty$ | async"> </app-type-property-user-list>
  <app-type-property-required
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  ></app-type-property-required>
  <app-type-property-uniqueness
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  ></app-type-property-uniqueness>
  <app-type-property-searchable
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  ></app-type-property-searchable>
  <app-type-property-federation
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  ></app-type-property-federation>
  <app-type-property-defaults (changes)="handleChange($event)" [typeProperty]="typeProperty$ | async">
  </app-type-property-defaults>
  <app-type-property-copy-behavior
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  >
  </app-type-property-copy-behavior>
  <app-type-property-carryover
    (changes)="handleChange($event)"
    [type]="type$ | async"
    [typeProperty]="typeProperty$ | async"
  ></app-type-property-carryover>

  <app-type-property-number-format [typeProperty]="typeProperty$ | async" (changes)="handleChange($event)">
  </app-type-property-number-format>

  <app-type-property-formula (changes)="handleChange($event)" [typeProperty]="typeProperty$ | async">
  </app-type-property-formula>
  <app-type-property-constraints (changes)="handleChange($event)" [typeProperty]="typeProperty$ | async">
  </app-type-property-constraints>
  <app-type-property-policy-assignment (changes)="handleChange($event)" [typeProperty]="typeProperty$ | async">
  </app-type-property-policy-assignment>
  <app-type-property-last-modified [typeProperty]="typeProperty$ | async"> </app-type-property-last-modified>
</div>
