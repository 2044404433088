import { updateOptionSetHierarchy } from './../../type-manager-store/option-set-hierarchies/option-set-hierarchies.actions';
import { Component, EventEmitter, OnDestroy, Output, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OptionSetHierarchy } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { Entities } from '@contrail/sdk';
import { StringUtil } from '@contrail/util';
import { set as _set, get as _get } from 'lodash';
import { Parser } from 'json2csv';
const csvToJson = require('csvtojson');
import { OptionSetHierarchiesService } from '../../type-manager-store/option-set-hierarchies/option-set-hierarchies.service';
import { TypeService } from '../../type.service';

@Component({
  selector: 'app-option-set-hierarchy-editor',
  templateUrl: './option-set-hierarchy-editor.component.html',
  styleUrls: ['./option-set-hierarchy-editor.component.scss'],
})
export class OptionSetHierarchyEditorComponent implements OnInit, OnDestroy {
  public editorFormControl: UntypedFormControl = new UntypedFormControl();
  private optionSetHierarchySub: Subscription;
  @Output() changes = new EventEmitter();
  isModalOpen = false;
  @Output() importProcessed = new EventEmitter<void>();
  public editorOptions: any = {
    theme: 'vs',
    language: 'json',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };
  optionSetHierarchyImportErrors: any[] = [];
  optionSetHierarchy$: Observable<OptionSetHierarchy>;
  optionSetHierarchy: OptionSetHierarchy;
  constructor(
    private store: Store<RootStoreState.State>,
    private optionSetHierarchiesService: OptionSetHierarchiesService,
    private typeService: TypeService,
  ) {
    this.getLatestCurrentOptionSetHierarchyFromStore();
  }

  ngOnInit(): void {
    this.typeService.getEntityUpdate().subscribe((data) => {
      if (data.id === this.optionSetHierarchy.id) {
        this.optionSetHierarchy = {
          ...this.optionSetHierarchy,
          ...data,
        };
      }
    });
  }

  getLatestCurrentOptionSetHierarchyFromStore() {
    this.optionSetHierarchy$ = this.store.select(TypeManagerSelectors.currentOptionSetHierarchy);
    this.optionSetHierarchySub = this.optionSetHierarchy$.subscribe((optionSetHierarchy) => {
      this.optionSetHierarchy = optionSetHierarchy;
      this.editorFormControl.setValue(JSON.stringify(this.optionSetHierarchy.options, null, 4));
    });
  }

  ngOnDestroy() {
    this.optionSetHierarchySub.unsubscribe();
  }

  save() {
    const options = JSON.parse(this.editorFormControl.value);
    this.store.dispatch(
      TypeManagerActions.updateOptionSetHierarchy({ id: this.optionSetHierarchy.id, changes: { options } }),
    );
  }
  toggleOptionSetHierarchyImportModal() {
    this.isModalOpen = !this.isModalOpen;
    this.optionSetHierarchyImportErrors = [];
  }

  async importOptionSetHierarchies(importedOptionSetHierarchies) {
    let localImportedOptionSetHierarchies = structuredClone(importedOptionSetHierarchies.jsonData);
    let parsedOptionSetHierarchiesJSON;

    try {
      if (importedOptionSetHierarchies.csvType !== 'keys') {
        const optionSetHierarchyCSVData = await this.parseOptionSetHierarchiesValuesToKeys(
          localImportedOptionSetHierarchies,
        );
        const parsedOptionSetHierarchy = await csvToJson({ flatKeys: true }).fromString(optionSetHierarchyCSVData);

        if (parsedOptionSetHierarchy.length !== localImportedOptionSetHierarchies.length) {
          const errors = [
            'CSV file contains some or all Keys in them. Try choosing "CSV with Keys" instead or modify the CSV and try again.',
          ];
          throw new Error(JSON.stringify(errors));
        }
      }

      parsedOptionSetHierarchiesJSON = await this.parseOptionSetHierarchies(localImportedOptionSetHierarchies);
    } catch (e) {
      console.error('Error while parsing Option Set Hierarchy', e.message);
      this.optionSetHierarchyImportErrors = JSON.parse(e.message);
      return;
    }

    await this.updateOptionSetHierarchy(parsedOptionSetHierarchiesJSON);
  }

  async updateOptionSetHierarchy(parsedOptionSetHierarchiesJSON) {
    try {
      const updatedOptionSetHierarchy = await this.optionSetHierarchiesService.update(this.optionSetHierarchy.id, {
        options: parsedOptionSetHierarchiesJSON.options,
      });
      this.optionSetHierarchy = updatedOptionSetHierarchy;
      this.importProcessed.emit();
      this.editorFormControl.setValue(JSON.stringify(updatedOptionSetHierarchy.options, null, 4));
      this.optionSetHierarchyImportErrors = [];
    } catch (e) {
      console.error('Error while updating Option Set Hierarchy');
      const updateErrors = JSON.parse(e.message);
      this.optionSetHierarchyImportErrors = updateErrors.errors;
    }
  }

  async parseOptionSetHierarchies(uploadedOptionHierarchyData): Promise<any> {
    const optionSetHierarchy = {
      hierarchy: [],
      maxDepth: 0,
      name: 'No Name',
      options: [],
    };

    if (!uploadedOptionHierarchyData?.length) {
      return optionSetHierarchy;
    }

    const keysInOrder = Object.keys(uploadedOptionHierarchyData[0]);
    const currentOptionSets = await new Entities().get({
      entityName: 'type-property-option-set',
    });

    const currentOptionSetsByName = {};
    for (const currentOptionSet of currentOptionSets) {
      currentOptionSetsByName[StringUtil.convertToCamelCase(currentOptionSet.name)] = currentOptionSet;
    }

    const optionSetIds = [];
    const optionSetErrors = [];
    for (const key of keysInOrder) {
      if (currentOptionSetsByName[key]) {
        optionSetIds.push(currentOptionSetsByName[key].id);
      } else {
        // if empty column is present in csv then it will show error as "No OptionSet found for field<column_number>" hence the logic
        if (key.startsWith('field')) {
          optionSetErrors.push(`No OptionSet found for ${key} (try to remove empty columns if any)`);
        } else {
          optionSetErrors.push(`No OptionSet found for ${key}`);
        }
      }
    }
    if (optionSetErrors.length) {
      throw new Error(JSON.stringify(optionSetErrors));
    }

    optionSetHierarchy.maxDepth = keysInOrder.length;
    optionSetHierarchy.hierarchy = optionSetIds;

    const hierarchy = {};
    const duplicate = [];
    for (const row of uploadedOptionHierarchyData) {
      const key = Object.values(row).join('.');

      const result = _get(hierarchy, key);
      if (!result) {
        _set(hierarchy, key, {});
      } else {
        duplicate.push(key);
      }
    }

    if (duplicate.length) {
      const errors = duplicate.map((key) => `Duplicate keys found in the hierarchy: ${key}`);
      throw new Error(JSON.stringify(errors));
    }

    optionSetHierarchy.options = this.buildOptionSetHierarchy(hierarchy, []);

    return optionSetHierarchy;
  }

  async parseOptionSetHierarchiesValuesToKeys(uploadedOptionHierarchyData): Promise<any> {
    if (!uploadedOptionHierarchyData?.length) {
      return [];
    }

    try {
      const keysInOrder = Object.keys(uploadedOptionHierarchyData[0]);
      const currentOptionSets = await new Entities().get({
        entityName: 'type-property-option-set',
      });

      for (const currentOptionSet of currentOptionSets) {
        currentOptionSet.name = StringUtil.convertToCamelCase(currentOptionSet.name);
      }

      const mapOptionSet = this.filterAndMapOptionSetData(currentOptionSets, keysInOrder);
      for (const row of uploadedOptionHierarchyData) {
        for (const key in row) {
          const value = mapOptionSet[key][row[key]];
          row[key] = value;
        }
      }

      const parser = new Parser(keysInOrder);
      const csv = parser.parse(uploadedOptionHierarchyData);
      return csv;
    } catch (e) {
      console.error(e);
      const errors = [`Error while parsing Option Set Hierarchy values to keys: ${e.message}`];
      throw new Error(JSON.stringify(errors));
    }
  }

  buildOptionSetHierarchy(hierarchy, result): any[] {
    const keys = Object.keys(hierarchy);

    if (!keys.length) return result;

    keys.forEach((k) => {
      if (k.trim()) {
        const optionSet = {
          value: k,
          options: [],
        };

        optionSet.options = this.buildOptionSetHierarchy(hierarchy[k], optionSet.options);

        if (!optionSet.options.length) {
          delete optionSet.options;
        }

        result.push(optionSet);
      }
    });

    return result;
  }

  filterAndMapOptionSetData(optionSets, headersMap): any {
    const requiredOptionSets = optionSets.filter((x) => headersMap.includes(x.name));

    if (requiredOptionSets.length !== headersMap.length) {
      const error = ['Headers from csv not matching with Option Set Hierarchy names.'];
      throw new Error(JSON.stringify(error));
    }

    const mapOptionSet = {};
    requiredOptionSets.forEach((element) => {
      const objOptionSet = {};
      element.optionSet.forEach((data) => {
        objOptionSet[data.display] = data.value;
      });
      mapOptionSet[element.name] = objOptionSet;
    });

    return mapOptionSet;
  }

  async convertHierarchyToCSV(optionSetHierarchy): Promise<string> {
    const optionSetIds = optionSetHierarchy.hierarchy;
    const optionSetsData = [];

    // Fetch option sets by IDs
    for (const optionSetId of optionSetIds) {
      const currentOptionSet = await new Entities().get({
        entityName: 'type-property-option-set',
        id: optionSetId,
      });
      currentOptionSet.name = StringUtil.convertToCamelCase(currentOptionSet.name);
      optionSetsData.push(currentOptionSet);
    }

    // Helper function to traverse the hierarchy and collect data
    const traverseHierarchy = (options, depth, row, result) => {
      if (depth >= optionSetIds.length) {
        result.push(row);
        return;
      }

      const optionSet = optionSetsData[depth];
      for (const option of options) {
        const newRow = { ...row, [optionSet.name]: option.value };
        if (option.options) {
          traverseHierarchy(option.options, depth + 1, newRow, result);
        } else {
          result.push(newRow);
        }
      }
    };

    const result = [];
    traverseHierarchy(optionSetHierarchy.options, 0, {}, result);

    // Convert the result to CSV
    const keysInOrder = optionSetsData.map((os) => os.name);
    const parser = new Parser({ fields: keysInOrder, quote: '' });
    const csv = parser.parse(result);

    return csv;
  }

  async exportOptionSetHierarchy() {
    const csv = await this.convertHierarchyToCSV(this.optionSetHierarchy);
    const fileName = this.generateFileName(this.optionSetHierarchy.name);
    this.downloadCSV(csv, fileName);
  }

  private generateFileName(type: string): string {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[-:]/g, '').slice(0, 12);
    return `${type}_${formattedDate}`;
  }

  private downloadCSV(csvContent: string, fileName: string) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
