<div class="content">
  <h6 class="py-2 border-b px-8">Files</h6>
  <div class="px-8 py-3">
    <div class="spinner-bg" *ngIf="isLoading">
      <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
    </div>

    <app-page-section *ngIf="!isLoading">
      <app-page-section-header>
        <app-page-section-title [title]="'Details'"></app-page-section-title>
      </app-page-section-header>
      <app-page-section-body>
        <span *ngIf="!found && !isLoading; else loaded" class="m-auto" data-test="not-found"
          >File with Id <span data-test="file-id" class="code">{{ fileId }}</span> was not found</span
        >
        <div class="spinner-bg" *ngIf="isLoading">
          <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
        </div>
        <ng-template #loaded>
          <div class="file-container">
            <div class="row flex">
              <div class="col-label" data-test="c1">Name</div>
              <div class="file-name" [matTooltip]="fileName" data-test="type-name">{{ fileName }}</div>
              <div>
                <mat-icon class="download-file" (click)="downloadFile()" data-test="download-data"
                  >file_download</mat-icon
                >
              </div>
            </div>
            <div class="row flex">
              <div class="col-label">Id</div>
              <div data-test="file-id">{{ fileId }}</div>
            </div>
            <div class="row flex">
              <div class="col-label">Size</div>
              <div data-test="size">{{ size }}</div>
            </div>
            <div class="row flex">
              <div class="col-label">Created on</div>
              <div data-test="createdOn">{{ createdOn | date: 'medium' }}</div>
            </div>
            <div class="row flex">
              <div class="col-label">Created by</div>
              <div data-test="email">{{ email }}</div>
            </div>
          </div>
        </ng-template>
      </app-page-section-body>
    </app-page-section>
  </div>
</div>
