import { createAction, props } from '@ngrx/store';
import { LoaderHistory, LoaderHistoryResponse } from './loader-history';

export enum LoaderHistoryActionTypes {
  LOAD_LOADER_HISTORY_ACTION = '[LOADER_HISTORY_ACTION] Load loader history Action',
  LOAD_LOADER_HISTORY_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load loader history Action Success',
  LOAD_LOADER_HISTORY_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load loader history Action Failure',
  LOAD_CURRENT_LOADER_HISTORY_ACTION = '[LOADER_HISTORY_ACTION] Load current loader history Action',
  LOAD_CURRENT_LOADER_HISTORY_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load current loader history Action Success',
  LOAD_CURRENT_LOADER_HISTORY_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load current loader history Action Failure',
  LOAD_S3_FILE_ACTION = '[LOADER_HISTORY_ACTION] Load S3 file history Action',
  LOAD_S3_FILE_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load S3 file history Action Success',
  LOAD_S3_FILE_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load S3 file history Action Failure',
  LOAD_S3_LOGS_ACTION = '[LOADER_HISTORY_ACTION] Load S3 Logs history Action',
  LOAD_S3_LOGS_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load S3 Logs history Action Success',
  LOAD_S3_LOGS_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load S3 Logs history Action Failure',
  LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION = '[LOADER_HISTORY_ACTION] Load Current Loader History Detail',
  LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load Current Loader History Detail Success',
  LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load Current Loader History Detail Failure',
  LOAD_PREPROCESSING_PIPELINE_DETAIL_ACTION = '[LOADER_HISTORY_ACTION] Load Preprocessing Pipeline Detail',
  LOAD_PREPROCESSING_PIPELINE_DETAIL_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load Preprocessing Pipeline Detail Failure',
  LOAD_PREPROCESSING_FILE_DETAIL_ACTION = '[LOADER_HISTORY_ACTION] Load Preprocessing File Detail',
  LOAD_PREPROCESSING_FILE_DETAIL_ACTION_SUCCESS = '[LOADER_HISTORY_ACTION] Load Preprocessing File Detail Success',
  LOAD_PREPROCESSING_FILE_DETAIL_ACTION_FAILURE = '[LOADER_HISTORY_ACTION] Load Preprocessing File Detail Failure',
}

export const loadLoaderHistoryAction = createAction(
  LoaderHistoryActionTypes.LOAD_LOADER_HISTORY_ACTION,
  props<{ startDate: string; endDate: string; hardRefresh: boolean }>(),
);

export const loadLoaderHistoryActionSuccess = createAction(
  LoaderHistoryActionTypes.LOAD_LOADER_HISTORY_ACTION_SUCCESS,
  props<{ data: LoaderHistoryResponse }>(),
);

export const loadLoaderHistoryActionFailure = createAction(
  LoaderHistoryActionTypes.LOAD_LOADER_HISTORY_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentLoaderHistoryAction = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_ACTION,
  props<{ processId: string }>(),
);

export const loadCurrentLoaderHistoryActionSuccess = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_ACTION_SUCCESS,
  props<{ data: LoaderHistory }>(),
);

export const loadCurrentLoaderHistoryActionFailure = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadS3LogsAction = createAction(
  LoaderHistoryActionTypes.LOAD_S3_LOGS_ACTION,
  props<{ downloadUrl: string; processLogs: string }>(),
);

export const loadS3LogsActionSuccess = createAction(
  LoaderHistoryActionTypes.LOAD_S3_LOGS_ACTION_SUCCESS,
  props<{ logText: string }>(),
);

export const loadCurrentLoaderHistoryDetailAction = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION,
  props<{ processId: string }>(),
);

export const loadCurrentLoaderHistoryDetailActionSuccess = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION_SUCCESS,
  props<{ data: LoaderHistory }>(),
);

export const loadCurrentLoaderHistoryDetailActionFailure = createAction(
  LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadPreprocessingPipelineDetailAction = createAction(
  LoaderHistoryActionTypes.LOAD_PREPROCESSING_PIPELINE_DETAIL_ACTION,
  props<{ fileId: string }>(),
);

export const loadPreprocessingPipelineDetailActionFailure = createAction(
  LoaderHistoryActionTypes.LOAD_PREPROCESSING_PIPELINE_DETAIL_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadPreprocessingFileDetailAction = createAction(
  LoaderHistoryActionTypes.LOAD_PREPROCESSING_FILE_DETAIL_ACTION,
  props<{ fileId: string; downloadUrl: string }>(),
);

export const loadPreprocessingFileDetailActionSuccess = createAction(
  LoaderHistoryActionTypes.LOAD_PREPROCESSING_FILE_DETAIL_ACTION_SUCCESS,
  props<{ fileId: string; data: any }>(),
);

export const loadPreprocessingFileDetailActionFailure = createAction(
  LoaderHistoryActionTypes.LOAD_PREPROCESSING_FILE_DETAIL_ACTION_FAILURE,
  props<{ error: any }>(),
);
