import { Component, Input } from '@angular/core';
import { OptionSetHierarchy } from '@contrail/types';
import { RoutingService } from '@common/routing/routing.service';

@Component({
  selector: 'app-option-set-hierarchy-editor-header',
  templateUrl: './option-set-hierarchy-editor-header.component.html',
  styleUrls: ['./option-set-hierarchy-editor-header.component.scss'],
})
export class OptionSetHierarchyEditorHeaderComponent {
  @Input() optionSetHierarchy: OptionSetHierarchy;
  constructor(private routingService: RoutingService) {}

  close() {
    this.routingService.go('/type-manager/option-set-hierarchies');
  }
}
