import { Component, Input } from '@angular/core';
import { TypePolicy } from '@contrail/types';
import { RoutingService } from '@common/routing/routing.service';
import { CONFIG_VIEW } from 'src/app/type-manager/types-helper';
import { TypeService } from '../../../type.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-type-policy-editor-header',
  templateUrl: './type-policy-editor-header.component.html',
  styleUrls: ['./type-policy-editor-header.component.scss'],
})
export class TypePolicyEditorHeaderComponent {
  @Input() typePolicy: TypePolicy;
  @Input() public activeView: CONFIG_VIEW = CONFIG_VIEW.UI;

  public view = CONFIG_VIEW;
  constructor(
    private routingService: RoutingService,
    private typeService: TypeService,
  ) {}

  close() {
    this.typeService.toggleDetailView(false);
    this.routingService.go('type-manager/type-policies');
  }

  selectView(event: MatButtonToggleChange) {
    this.activeView = event.value;
    this.typeService.toggleConfigVisibility(event.value);
  }
}
