import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface TypePolicy {
  id?: string;
  label?: string;
  identifier?: string;
  policy?: any;
}

export interface TypePoliciesState extends EntityState<TypePolicy> {}
export const typePoliciesEntityAdapter: EntityAdapter<TypePolicy> = createEntityAdapter<TypePolicy>({});

export const typePoliciesInitialState = typePoliciesEntityAdapter.getInitialState({});
