import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeProperty } from '@contrail/types';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-type-property-last-modified',
  templateUrl: './type-property-last-modified.component.html',
  styleUrls: ['./type-property-last-modified.component.scss'],
})
export class TypePropertyLastModifiedComponent implements OnInit {
  @Input() typeProperty: TypeProperty;
  private snackBar: MatSnackBar;
  properties;
  user;
  constructor() {}

  async ngOnInit() {
    this.properties = this.typeProperty;
    this.user = await this.getUser(this.properties?.updatedById);
  }

  private async getUser(id: string) {
    let user;
    try {
      user = await new Entities().get({ entityName: 'user', id });
      console.log(user);
    } catch (e) {
      this.snackBar.open(e, '', { duration: 2000 });
      console.error(e);
    }
    return user;
  }
}
