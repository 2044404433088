import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-type-property-usage-removal',
  templateUrl: './type-property-usage-removal.component.html',
  styleUrls: ['./type-property-usage-removal.component.scss'],
})
export class TypePropertyUsageRemovalComponent {
  @Input() typeProperty: any;
  @Output() changes = new EventEmitter();
  constructor() {}

  deleteUsageLink() {
    const valueChanges = {
      infoLinkId: null,
    };
    this.changes.emit(valueChanges);
  }
}
