import { Component, Input } from '@angular/core';
import { RoutingService } from '@common/routing/routing.service';
import { TypeRuleSet } from 'src/app/type-manager/type-manager-store/type-rule-sets/type-rule-sets.state';

@Component({
  selector: 'app-type-rule-set-editor-header',
  templateUrl: './type-rule-set-editor-header.component.html',
  styleUrls: ['./type-rule-set-editor-header.component.scss'],
})
export class TypeRuleSetEditorHeaderComponent {
  @Input() typeRuleSet: TypeRuleSet;
  constructor(private routingService: RoutingService) {}

  close() {
    this.routingService.go('/type-manager/rule-sets');
  }
}
