import { Component, Input, OnChanges } from '@angular/core';
import { PropertyTypeMeta } from '@contrail/types';
import { PropertyType } from '@contrail/types';

@Component({
  selector: 'app-type-property-icon',
  templateUrl: './type-property-icon.component.html',
  styleUrls: ['./type-property-icon.component.scss'],
})
export class TypePropertyIconComponent implements OnChanges {
  @Input() propertyType: PropertyType;
  public tooltip: string;
  public icon: string;
  constructor() {}

  ngOnChanges(): void {
    if (this.propertyType) {
      this.icon = this.getIconForPropertyType(this.propertyType);
      this.tooltip = PropertyTypeMeta.get(this.propertyType)?.label;
    }
  }
  getIconForPropertyType(propertyType: PropertyType) {
    switch (propertyType) {
      case PropertyType.Date: {
        return 'today';
      }
      case PropertyType.String: {
        return 'title';
      }
      case PropertyType.Text: {
        return 'format_indent_increase';
      }
      case PropertyType.Boolean: {
        return 'toggle_on';
      }
      case PropertyType.Currency: {
        return 'attach_money';
      }
      case PropertyType.SingleSelect: {
        return 'arrow_drop_down_circle';
      }
      case PropertyType.MultiSelect: {
        return 'check_box';
      }
      case PropertyType.Formula: {
        return 'functions';
      }
      case PropertyType.Number: {
        return 'tag';
      }
      case PropertyType.Image: {
        return 'image';
      }
      case PropertyType.TypeReference:
      case PropertyType.ObjectReference: {
        return 'arrow_right_alt';
      }
      case PropertyType.Sequence: {
        return 'linear_scale';
      }
      case PropertyType.Percent: {
        return 'percentage';
      }
      case PropertyType.Url: {
        return 'link';
      }
    }
    return;
  }
}
