import { Injectable } from '@angular/core';
import { API_VERSION, Entities } from '@contrail/sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderHistoryResponse } from './loader-history';

@Injectable({
  providedIn: 'root',
})
export class LoaderHistoryService {
  constructor(private http: HttpClient) {}

  async fetchLoaderProcessBetweenRange(startDate, endDate): Promise<LoaderHistoryResponse> {
    const createdOnCriteria = `BETWEEN ${startDate} and ${endDate}`;
    let nextPageKey;
    const loads = [];
    do {
      const loadPage = await new Entities().get({
        entityName: 'loader-process',
        criteria: { createdOn: createdOnCriteria },
        apiVersion: API_VERSION.V2,
        nextPageKey,
        relations: ['createdBy'],
      });
      nextPageKey = loadPage.nextPageKey;
      loads.push(...loadPage.results);
    } while (nextPageKey);

    return {
      results: loads,
      nextPageKey,
    };
  }

  async fetchS3Log(downloadUrl: string): Promise<string> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'application/text',
      }),
      responseType: 'blob' as 'json',
    };
    const result = (await this.http.get(downloadUrl, HTTPOptions).toPromise()) as any;
    const text = await result.text();
    return text;
  }

  async fetchCurrentLoaderProcess(processId: string) {
    return new Entities().get({
      entityName: 'loader-process',
      id: processId,
      apiVersion: API_VERSION.V2,
      relations: ['createdBy'],
    });
  }

  fetchCurrentLoaderProcessDetail() {
    return this.http.get('assets/loader-history-details.json');
  }

  fetchPreprocessingPipelineDetail(fileId: string) {
    return new Entities().get({
      entityName: 'file',
      id: fileId,
      apiVersion: API_VERSION.V2,
    });
  }

  fetchPreprocessingFileDetail(downloadUrl: string) {
    return this.http.get(downloadUrl);
  }
}
