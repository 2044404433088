<div *ngIf="optionSetHierarchy" class="px-8 py-3 overflow-auto h-full">
  <app-option-set-hierarchy-editor-header [optionSetHierarchy]="optionSetHierarchy">
  </app-option-set-hierarchy-editor-header>

  <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Options"></app-page-section-title>
    </app-page-section-header>
    <app-page-section-body data-test="option-set-hierarchy-editor">
      <ngx-monaco-editor
        class="border p-4 block h-96 w-full mb-2"
        [formControl]="editorFormControl"
        [options]="editorOptions"
        #editor
      >
      </ngx-monaco-editor>
      <button mat-raised-button [disabled]="!editorFormControl?.valid" (click)="save()">Save</button>
      <button mat-button class="px-2 mx-2" (click)="toggleOptionSetHierarchyImportModal()" data-test="import-button">
        <mat-icon>cloud_upload</mat-icon> Import
      </button>
      <button mat-button class="!px-2" (click)="exportOptionSetHierarchy()" data-test="export-button">
        <mat-icon>cloud_download</mat-icon> Export
      </button>
    </app-page-section-body>
  </app-page-section>
  <app-import-modal
    *ngIf="isModalOpen"
    (cancel)="toggleOptionSetHierarchyImportModal()"
    (importOptionSetHierarchies)="importOptionSetHierarchies($event)"
    [isUploadProcessed]="importProcessed"
    importType="OPTION_SET_HIERARCHY"
    [errors]="optionSetHierarchyImportErrors"
  ></app-import-modal>
</div>
