import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
const csvToJson = require('csvtojson');
import yaml from 'js-yaml';
@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent {
  isLoading = false;
  errorMessage: string | null = null;
  warningMessage: string | null = null;
  successMessage: string | null = null;
  jsonData: Object[] = [];
  currentFile: File | null = null;
  constructor(private snackBar: MatSnackBar) {}
  @Output() cancel = new EventEmitter<void>();
  @Output() uploadUsers = new EventEmitter<void>();
  @Input() isUploadProcessed: EventEmitter<void>;
  @Input() importType: string;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    const files: FileList = event.dataTransfer?.files;
    if (files && files.length) {
      const file = files[0];
      this.handleLoadFile(file);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isUploadProcessed']) {
      this.isUploadProcessed.subscribe(() => {
        this.postUpload();
      });
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.handleLoadFile(file);
    }
  }

  async handleLoadFile(file: File) {
    this.errorMessage = null;
    this.isLoading = true;
    if (!this.isValidFileType(file)) {
      this.errorMessage = 'Invalid file type. Only CSV and YAML files are allowed.';
      this.isLoading = false;
      return;
    }

    this.currentFile = file;
    try {
      const dataset = await this.readFileAsText(file);
      const data = await this.parseData(file.name, dataset);
      this.jsonData = data;
    } catch (error) {
      this.errorMessage = error.message;
      console.error('Error processing file:', error);
      this.snackBar.open(this.errorMessage, 'Close', {
        duration: 2000,
      });
    } finally {
      this.isLoading = false;
    }
  }

  private isValidFileType(file: File): boolean {
    const allowedExtensions = ['.csv', '.yaml', '.yml'];
    const fileName = file?.name?.toLowerCase();
    const fileExtension = fileName?.slice(fileName?.lastIndexOf('.'));
    const isValidExtension = allowedExtensions.includes(fileExtension);
    return isValidExtension;
  }

  private readFileAsText(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.onerror = () => reject(new Error('Failed to read file.'));
      fileReader.readAsText(file);
    });
  }

  private async parseData(fileName: string, dataset: string): Promise<any[]> {
    if (fileName.endsWith('.csv')) {
      const jsonData = await csvToJson({ flatKeys: true }).fromString(dataset);
      if (!jsonData.length) {
        this.errorMessage = 'No data found in the CSV file.';
        return;
      }
      // To ensure the headers are in lowercase
      const lowercasedUserRows = jsonData.map((userRow) => {
        const newRow: any = {};
        Object.entries(userRow).forEach(([key, value]) => {
          const lowercasedKey = key.toLowerCase().trim();
          if (lowercasedKey === 'first name') {
            newRow.firstName = value;
          } else if (lowercasedKey === 'last name') {
            newRow.lastName = value;
          } else {
            newRow[lowercasedKey] = value;
          }
        });
        return newRow;
      });

      // Check if email header is present
      const missingEmailHeader = !lowercasedUserRows[0].hasOwnProperty('email');
      if (missingEmailHeader) {
        this.errorMessage = 'email header is missing in the CSV file.';
        return;
      }

      // Check if email is present in all entries
      const missingEmailData = lowercasedUserRows.filter((userObj) => !userObj.email);
      if (missingEmailData.length > 0) {
        this.errorMessage = 'email is required for all entries.';
        return;
      }

      // Check if first name, last name, and role are present
      const missingUserData = lowercasedUserRows.filter((userObj) => !userObj.role);
      if (missingUserData.length > 0) {
        this.warningMessage = 'role missing for some or all the entries.';
      }

      // to ensure the email is in lowercase and role is in uppercase
      const validatedUserRows = lowercasedUserRows.map((userObj) => {
        return {
          ...userObj,
          email: userObj.email.toLowerCase(),
          role: userObj.role.toUpperCase(),
        };
      });
      return validatedUserRows;
    } else if (fileName.endsWith('.yaml') || fileName.endsWith('.yml')) {
      return yaml.load(dataset);
    } else {
      this.errorMessage = 'Invalid file type. Only CSV and YAML files are allowed.';
    }
  }

  async handleImport() {
    const data = this.jsonData;
    await this.uploadData(data);
  }

  private async uploadData(data: any): Promise<void> {
    this.isLoading = true;
    this.uploadUsers.emit(data);
  }

  private async postUpload() {
    this.successMessage = 'Data uploaded successfully.';
    this.snackBar.open(this.successMessage, 'Close', {
      duration: 2000,
    });
    this.closeModal();
    this.isLoading = false;
  }

  closeModal() {
    this.isLoading = false;
    this.errorMessage = null;
    this.successMessage = null;
    this.cancel.emit();
  }
}
