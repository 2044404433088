import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OptionSetHierarchy } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';

@Component({
  selector: 'app-option-set-hierarchy-editor',
  templateUrl: './option-set-hierarchy-editor.component.html',
  styleUrls: ['./option-set-hierarchy-editor.component.scss'],
})
export class OptionSetHierarchyEditorComponent implements OnDestroy {
  public editorFormControl: UntypedFormControl = new UntypedFormControl();
  private optionSetHierarchySub: Subscription;
  @Output() changes = new EventEmitter();
  public editorOptions: any = {
    theme: 'vs',
    language: 'json',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };
  optionSetHierarchy$: Observable<OptionSetHierarchy>;
  optionSetHierarchy: OptionSetHierarchy;
  constructor(private store: Store<RootStoreState.State>) {
    this.optionSetHierarchy$ = this.store.select(TypeManagerSelectors.currentOptionSetHierarchy);
    this.optionSetHierarchySub = this.optionSetHierarchy$.subscribe((optionSetHierarchy) => {
      this.optionSetHierarchy = optionSetHierarchy;
      this.editorFormControl.setValue(JSON.stringify(this.optionSetHierarchy.options, null, 4));
    });
  }

  ngOnDestroy() {
    this.optionSetHierarchySub.unsubscribe();
  }

  save() {
    const options = JSON.parse(this.editorFormControl.value);
    this.store.dispatch(
      TypeManagerActions.updateOptionSetHierarchy({ id: this.optionSetHierarchy.id, changes: { options } }),
    );
  }
}
