import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { TypesService } from '../type-manager/types.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
})
export class EntitiesComponent {
  public entityType: string;
  public criteria: string;
  public relations: Array<string>;
  public results: Array<any>;
  public resultsString;
  public error;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private typesService: TypesService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.entityType = params.entityType;
      this.get();
    });
  }

  async get() {
    const config: any = {};
    config.entityName = this.entityType;
    config.relations = this.relations || ['item'];
    if (this.criteria) {
      config.criteria = JSON.parse(this.criteria);
    }

    try {
      this.results = await new Entities().get(config);
      this.resultsString = JSON.stringify(this.results, null, 4);
    } catch (error) {
      this.error = error;
    }
  }

  format(json) {
    return JSON.stringify(json, null, 4);
  }
  go(obj) {
    this.router.navigate([`${this.entityType}/${obj.id}`], { relativeTo: this.activatedRoute });
  }
  async getType(entity: any) {
    const type: Type = await this.typesService.getTypeById(entity.typeId);
    return type.typePath;
  }
}
