import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Group } from './group';

interface GroupsState extends EntityState<Group> {}

export interface State {
  groups: GroupsState;
  currentGroup: Group;
}
export const groupsEntityAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({});

export const groupsInitialState = {
  groups: groupsEntityAdapter.getInitialState({}),
  currentGroup: null,
};
