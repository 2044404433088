<div class="modal">
  <div class="modal-content justify-between">
    <div class="flex justify-between">
      <h6>Import {{ importType }}</h6>
      <mat-icon [mat-dialog-close]="false" (click)="closeModal()" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
    </div>
    <div class="flex justify-center w-full mt-2 mb-2">
      <div class="drop-zone">
        <div class="flex flex-col justify-center items-center pt-5 mt-2">
          <mat-icon class="upload-icon"> upload </mat-icon>
          <div class="upload-container mt-2 pt-2">
            Drag a CSV here or
            <span class="underline cursor-pointer mx-1" (click)="fileUpload.click()">select</span> from your computer
          </div>
          <div class="flex justify-center mt-2">
            <img src="assets/images/user-import-eg.png" alt="import users example image" class="w-10/12 rounded" />
          </div>
          <input #fileUpload type="file" style="display: none" (change)="onFileSelected($event)" />
        </div>
      </div>
    </div>
    <div *ngIf="currentFile && !isLoading && !errorMessage && !successMessage">
      <p class="mt-3">Selected File: {{ currentFile.name }}</p>
    </div>
    <div *ngIf="isLoading; else errorSuccessBlock">
      <div class="spinner-bg">
        <mat-spinner class="spinner" diameter="40"></mat-spinner>
      </div>
    </div>

    <ng-template #errorSuccessBlock>
      <div *ngIf="errorMessage">
        <p>{{ errorMessage }}</p>
      </div>
      <div *ngIf="warningMessage">
        <p>Warning: {{ warningMessage }}</p>
      </div>
      <div *ngIf="successMessage">
        <p>{{ successMessage }}</p>
      </div>
    </ng-template>
    <div class="flex items-center justify-end mt-3">
      <button mat-stroked-button color="primary" (click)="closeModal()" class="!mr-3">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        (click)="handleImport()"
        [disabled]="isLoading || !currentFile || errorMessage"
      >
        Import
      </button>
    </div>
  </div>
</div>
