<div
  class="list-item"
  *ngFor="let property of properties"
  [ngClass]="{ selectable: selectable }"
  (click)="handleClick(property)"
  data-test="type-property"
>
  <app-type-property-icon [propertyType]="property.propertyType"></app-type-property-icon>
  <div class="list-value code" style="width: 200px" [matTooltip]="property.slug">{{ property.slug | shorten: 20 }}</div>
  <div class="list-value">{{ property.label }}</div>
  <div class="list-action" *ngIf="showActions">
    <button mat-button *ngIf="!property.inherited" (click)="handleDelete(property)"><mat-icon>delete</mat-icon></button>
  </div>
</div>
