<div class="header flex-between-center">
  <div class="left-header">
    <h6>{{ optionSetHierarchy?.name }}</h6>
  </div>
  <div class="right-header flex-center">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
</div>
<div class="my-2 flex">
  ID:
  <div class="slug code ml-3">{{ optionSetHierarchy.id }}</div>
</div>
