import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';

import { TypesService } from '../../types.service';
import { TypePropertyPoliciesService } from './type-property-policies.service';

@Injectable()
export class TypePropertyPoliciesEffects {
  constructor(
    private actions$: Actions,
    private typePropertyPolicyService: TypePropertyPoliciesService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadTypePropertyPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyPoliciesActionTypes.LOAD_TYPE_PROPERTY_POLICIES),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.typePropertyPolicyService.get()).pipe(
          map((data) => {
            this.store.dispatch(TypeManagerActions.loading({ loading: false }));
            return TypeManagerActions.loadTypePropertyPoliciesSuccess({ data });
          }),
          catchError((error) => {
            this.store.dispatch(TypeManagerActions.loading({ loading: false }));
            return observableOf(TypeManagerActions.loadTypePropertyPoliciesFailure({ error }));
          }),
        ),
      ),
    ),
  );
  createTypePropertyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyPoliciesActionTypes.CREATE_TYPE_PROPERTY_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyPolicyService.create(action.typePropertyPolicy)).pipe(
          map((data) => {
            this.snackBar.open('Policy created.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.setCurrentTypePropertyPolicy({ currentTypePropertyPolicy: data }));
            return TypeManagerActions.createTypePropertyPolicySuccess({ typePropertyPolicy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createTypePropertyPolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteTypePropertyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyPoliciesActionTypes.DELETE_TYPE_PROPERTY_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyPolicyService.delete(action.typePropertyPolicy)).pipe(
          map((data) => {
            this.snackBar.open('Policy deleted.', '', { duration: 2000 });
            return TypeManagerActions.deleteTypePropertyPolicySuccess({ typePropertyPolicy: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteTypePropertyPolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateTypePropertyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyPoliciesActionTypes.UPDATE_TYPE_PROPERTY_POLICY),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyPolicyService.update(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Policy updated.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.updating({ updating: false }));
            return TypeManagerActions.updateTypePropertyPolicySuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.updating({ updating: false }));
            return observableOf(TypeManagerActions.updateTypePropertyPolicyFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
