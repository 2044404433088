import { TypeProperty } from '@contrail/types';
import { createAction, props } from '@ngrx/store';

export enum TypePropertiesActionTypes {
  SET_TYPE_PROPERTIES = '[Type Manager - Type Properties] Set Type Properties',

  LOAD_TYPE_PROPERTIES = '[Type Manager - Type Properties] Load Type Properties',
  LOAD_TYPE_PROPERTIES_SUCCESS = '[Type Manager - Type Properties] Load Type Properties Success',
  LOAD_TYPE_PROPERTIES_FAILURE = '[Type Manager - Type Properties] Load Type Properties Failure',

  CREATE_TYPE_PROPERTY = '[Type Manager - Type Properties] Create Type Property',
  CREATE_TYPE_PROPERTY_SUCCESS = '[Type Manager - Type Properties] Create Type Property Success',
  CREATE_TYPE_PROPERTY_FAILURE = '[Type Manager - Type Properties] Create Type Property Failure',

  DELETE_TYPE_PROPERTY = '[Type Manager - Type Properties] Delete Type Property',
  DELETE_TYPE_PROPERTY_SUCCESS = '[Type Manager - Type Properties] Delete Type Property Success',
  DELETE_TYPE_PROPERTY_FAILURE = '[Type Manager - Type Properties] Delete Type Property Failure',

  UPDATE_TYPE_PROPERTY = '[Type Manager - Type Properties] Update Type Property',
  UPDATE_TYPE_PROPERTY_SUCCESS = '[Type Manager - Type Properties] Update Type Property Success',
  UPDATE_TYPE_PROPERTY_FAILURE = '[Type Manager - Type Properties] Update Type Property Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const setTypeProperties = createAction(
  TypePropertiesActionTypes.SET_TYPE_PROPERTIES,
  props<{ data: Array<any> }>(),
);
export const loadTypeProperties = createAction(TypePropertiesActionTypes.LOAD_TYPE_PROPERTIES);

export const loadTypePropertiesSuccess = createAction(
  TypePropertiesActionTypes.LOAD_TYPE_PROPERTIES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypePropertiesFailure = createAction(
  TypePropertiesActionTypes.LOAD_TYPE_PROPERTIES_FAILURE,
  props<{ error: any }>(),
);

export const createTypeProperty = createAction(
  TypePropertiesActionTypes.CREATE_TYPE_PROPERTY,
  props<{ typeProperty: TypeProperty }>(),
);

export const createTypePropertySuccess = createAction(
  TypePropertiesActionTypes.CREATE_TYPE_PROPERTY_SUCCESS,
  props<{ typeProperty: TypeProperty }>(),
);

export const createTypePropertyFailure = createAction(
  TypePropertiesActionTypes.CREATE_TYPE_PROPERTY_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypeProperty = createAction(
  TypePropertiesActionTypes.DELETE_TYPE_PROPERTY,
  props<{ typeProperty: TypeProperty }>(),
);

export const deleteTypePropertySuccess = createAction(
  TypePropertiesActionTypes.DELETE_TYPE_PROPERTY_SUCCESS,
  props<{ typeProperty: TypeProperty }>(),
);

export const deleteTypePropertyFailure = createAction(
  TypePropertiesActionTypes.DELETE_TYPE_PROPERTY_FAILURE,
  props<{ error: any }>(),
);

export const updateTypeProperty = createAction(
  TypePropertiesActionTypes.UPDATE_TYPE_PROPERTY,
  props<{ id: string; changes: TypeProperty }>(),
);

export const updateTypePropertySuccess = createAction(
  TypePropertiesActionTypes.UPDATE_TYPE_PROPERTY_SUCCESS,
  props<{ id: string; changes: TypeProperty }>(),
);

export const updateTypePropertyFailure = createAction(
  TypePropertiesActionTypes.UPDATE_TYPE_PROPERTY_FAILURE,
  props<{ error: any }>(),
);
