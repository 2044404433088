import { createAction, props } from '@ngrx/store';
import { Group } from './group';

export enum GroupsActionTypes {
  LOAD_GROUPS = '[Groups] Load Groups',
  LOAD_GROUPS_SUCCESS = '[Groups] Load Groups Success',
  LOAD_GROUPS_FAILURE = '[Groups] Load Groups Failure',

  CREATE_GROUP = '[Groups] Create Group',
  CREATE_GROUP_SUCCESS = '[Groups] Create Group Success',
  CREATE_GROUP_FAILURE = '[Groups] Create Group Failure',

  DELETE_GROUP = '[Groups] Delete Group',
  DELETE_GROUP_SUCCESS = '[Groups] Delete Group Success',
  DELETE_GROUP_FAILURE = '[Groups] Delete Group Failure',

  DELETE_GROUPS = '[Groups] Delete Groups',
  DELETE_GROUPS_SUCCESS = '[Groups] Delete Groups Success',
  DELETE_GROUPS_FAILURE = '[Groups] Delete Groups Failure',

  UPDATE_GROUP = '[Groups] Update Group',
  UPDATE_GROUP_SUCCESS = '[Groups] Update Group Success',
  UPDATE_GROUP_FAILURE = '[Groups] Update Group Failure',

  LOAD_CURRENT_GROUP = '[Groups] Load Current Group',
  LOAD_CURRENT_GROUP_SUCCESS = '[Groups] Load Current Group Success',
  LOAD_CURRENT_GROUP_FAILURE = '[Groups] Load Current Group Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadGroups = createAction(GroupsActionTypes.LOAD_GROUPS);

export const loadGroupsSuccess = createAction(GroupsActionTypes.LOAD_GROUPS_SUCCESS, props<{ data: Array<any> }>());

export const loadGroupsFailure = createAction(GroupsActionTypes.LOAD_GROUPS_FAILURE, props<{ error: any }>());

export const createGroup = createAction(GroupsActionTypes.CREATE_GROUP, props<{ name: string }>());

export const createGroupSuccess = createAction(GroupsActionTypes.CREATE_GROUP_SUCCESS, props<{ group: Group }>());

export const createGroupFailure = createAction(GroupsActionTypes.CREATE_GROUP_FAILURE, props<{ error: any }>());

export const updateGroup = createAction(GroupsActionTypes.UPDATE_GROUP, props<{ id: string; changes: Group }>());

export const updateGroupSuccess = createAction(
  GroupsActionTypes.UPDATE_GROUP_SUCCESS,
  props<{ id: string; changes: Group }>(),
);

export const updateGroupFailure = createAction(GroupsActionTypes.UPDATE_GROUP_FAILURE, props<{ error: any }>());

export const deleteGroup = createAction(GroupsActionTypes.DELETE_GROUP, props<{ group: Group }>());

export const deleteGroupSuccess = createAction(GroupsActionTypes.DELETE_GROUP_SUCCESS, props<{ group: Group }>());

export const deleteGroupFailure = createAction(GroupsActionTypes.DELETE_GROUP_FAILURE, props<{ error: any }>());

export const deleteGroups = createAction(GroupsActionTypes.DELETE_GROUPS, props<{ ids: string[] }>());

export const deleteGroupsSuccess = createAction(GroupsActionTypes.DELETE_GROUPS_SUCCESS, props<{ ids: string[] }>());

export const deleteGroupsFailure = createAction(GroupsActionTypes.DELETE_GROUPS_FAILURE, props<{ error: any }>());

export const loadCurrentGroup = createAction(GroupsActionTypes.LOAD_CURRENT_GROUP, props<{ id: string }>());

export const loadCurrentGroupSuccess = createAction(
  GroupsActionTypes.LOAD_CURRENT_GROUP_SUCCESS,
  props<{ group: Group }>(),
);

export const loadCurrentGroupFailure = createAction(
  GroupsActionTypes.LOAD_CURRENT_GROUP_FAILURE,
  props<{ error: any }>(),
);
