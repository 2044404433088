import { Component, Input } from '@angular/core';
import { RoutingService } from '@common/routing/routing.service';
import { TypePropertyPolicy } from 'src/app/type-manager/type-manager-store/type-property-policies/type-property-policies.state';
import { CONFIG_VIEW } from 'src/app/type-manager/types-helper';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { TypeService } from 'src/app/type-manager/type.service';

@Component({
  selector: 'app-type-property-policy-editor-header',
  templateUrl: './type-property-policy-editor-header.component.html',
  styleUrls: ['./type-property-policy-editor-header.component.scss'],
})
export class TypePropertyPolicyEditorHeaderComponent {
  @Input() public typePropertyPolicy: TypePropertyPolicy;
  @Input() public activeView: CONFIG_VIEW = CONFIG_VIEW.UI;

  public view = CONFIG_VIEW;
  constructor(
    private routingService: RoutingService,
    private typeService: TypeService,
  ) {}

  close() {
    this.typeService.toggleDetailView(false);
    this.routingService.go('/type-manager/type-property-policies');
  }

  selectView(event: MatButtonToggleChange) {
    this.activeView = event.value;
    this.typeService.toggleConfigVisibility(event.value);
  }
}
