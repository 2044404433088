import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, Inject, ViewChild, Component, OnInit } from '@angular/core';

import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { TypesService } from 'src/app/type-manager/types.service';
import { TypePropertyFormComponent } from 'src/app/common/types/forms/type-property-form/type-property-form.component';
import { RoutingService } from 'src/app/common/routing/routing.service';

@Component({
  selector: 'app-create-entity',
  templateUrl: './create-entity.component.html',
  styleUrls: ['./create-entity.component.scss'],
})
export class CreateEntityComponent implements OnInit, AfterViewInit {
  private entityType: string;
  private typeId: string;
  public type: Type;
  public values: any = {};
  @ViewChild(TypePropertyFormComponent) typePropertyForm: TypePropertyFormComponent;
  constructor(
    public dialogRef: MatDialogRef<CreateEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private routingService: RoutingService,
    private typesService: TypesService,
  ) {
    this.entityType = data.entityType;
    this.typeId = data.typeId;
  }

  async ngOnInit() {
    this.type = await this.typesService.getTypeById(this.typeId);
    this.values.typeId = this.type.id;
  }
  ngAfterViewInit() {
    this.typePropertyForm.formChanges.subscribe((change) => {
      console.log('Change: ', change);
      this.values[change.propertySlug] = change.value;
    });
  }

  async create() {
    console.log('values: ', this.values);
    const newObj = await new Entities().create({ entityName: this.entityType, object: this.values });
    console.log('newObj: ', newObj);
    this.routingService.go(`entities/details/item/${newObj.id}/`);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
