import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TypePropertyOption } from '@contrail/types';

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ObjectUtil } from '@contrail/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypePropertyOptionSet } from 'src/app/type-manager/type-manager-store/option-sets/option-sets.state';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
@Component({
  selector: 'app-type-property-option-set-option-editor-option-list',
  templateUrl: './type-property-option-set-option-editor-option-list.component.html',
  styleUrls: ['./type-property-option-set-option-editor-option-list.component.scss'],
})
export class TypePropertyOptionSetOptionEditorOptionListComponent implements OnChanges {
  @Input() typePropertyOptionSet: TypePropertyOptionSet;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  public optionSet: Array<TypePropertyOption>;
  public edit = false;
  public showAdd = false;
  public displayField = new UntypedFormControl('', Validators.required);
  public valueField = new UntypedFormControl('', Validators.required);
  public searchKey = '';
  public displayTextLengthLimit = 9;

  constructor(
    private el: ElementRef,
    private snackBar: MatSnackBar,
    private UpdateDialogService: UpdateDialogService,
  ) {}

  ngOnChanges() {
    this.optionSet = ObjectUtil.cloneDeep(this.typePropertyOptionSet.optionSet || []);
    this.showAdd = false;
  }
  toggleEdit() {
    this.edit = !this.edit;
  }
  showAddOption() {
    this.showAdd = true;
  }
  addOption() {
    // CHECK FOR DUPLICATE
    const existing = this.optionSet.find((o) => o.value === this.valueField.value);
    if (existing) {
      this.snackBar.open(`Duplicate value already exists:  '${this.valueField.value}'.`, null, { duration: 2000 });
      return;
    }

    this.optionSet = this.optionSet.concat([{ value: this.valueField.value, display: this.displayField.value }]);
    this.valueField.reset();
    this.displayField.reset();
    const valueInput = document.getElementById('valueInput');
    valueInput.focus();
    this.updated.emit(this.optionSet);
  }
  removeOption() {}
  modifyOption() {}
  isNewValid() {
    return !(this.displayField.invalid || this.valueField.invalid);
  }
  setDisplayFromValue() {
    const value = this.valueField.value;
    if (!value) {
      return;
    }
    let labelForSlug = value.replace(/([A-Z])/g, ' $1');
    labelForSlug = labelForSlug.charAt(0).toUpperCase() + labelForSlug.slice(1);
    this.displayField.setValue(labelForSlug);
  }

  handleDisableToggle(event, updatedOption) {
    const isDisabled = event.checked;
    this.optionSet = this.optionSet.map((option) => {
      if (option.value === updatedOption.value) {
        return { ...option, disabled: isDisabled };
      }
      return option;
    });

    this.updated.emit(this.optionSet);
  }

  async handleEdit(option) {
    const updatedDisplay = await this.UpdateDialogService.openDialog('Rename Value Label', option.display);
    if (updatedDisplay !== option.display) {
      const updatedOption = { ...option, display: updatedDisplay };
      const updatedOptionSet = this.optionSet.map((o) => (o.value === option.value ? updatedOption : o));
      this.optionSet = updatedOptionSet;
      this.updated.emit(updatedOptionSet);
    }
  }

  handleDelete(option) {
    this.optionSet = this.optionSet.filter((o) => o.value !== option.value);
    this.updated.emit(this.optionSet);
  }

  drop(event) {
    this.optionSet = ObjectUtil.cloneDeep(this.optionSet || []);
    moveItemInArray(this.optionSet, event.previousIndex, event.currentIndex);
    this.updated.emit(ObjectUtil.cloneDeep(this.optionSet));
  }
}
