<app-page-section *ngIf="isObjectReferenceProperty">
  <app-page-section-header>
    <app-page-section-title
      *ngIf="typeProperty.propertyType === 'object_reference'"
      title="Entity Reference"
    ></app-page-section-title>
    <app-page-section-title
      *ngIf="typeProperty.propertyType === 'type_reference'"
      title="Type Reference"
    ></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <ng-container *ngIf="referencedTypeRoot?.id && referencedTypeRoot?.id === referencedType?.id">
      This property references type <mat-chip>{{ referencedTypeRoot?.label }}</mat-chip>
      <div *ngIf="typeProperty.referencedTypeRole">
        Item Role: <span class="code ml-5">{{ typeProperty.referencedTypeRole }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="referencedTypeRoot?.id && referencedTypeRoot?.id !== referencedType?.id">
      This property references type <mat-chip>{{ referencedTypeRoot?.label }} / {{ referencedType?.label }}</mat-chip>
    </ng-container>
  </app-page-section-body>
</app-page-section>
