import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { EventWorkflowTemplate } from './event-template';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowTemplatesService {
  constructor() {}

  // Get all templates by Org
  public async getEventWorkflowTemplates() {
    return new Entities().get({ entityName: 'event-workflow-template', relations: ['createdBy'] });
  }
  public async getEventWorkflowTemplatesById(id: string) {
    return new Entities().get({ entityName: 'event-workflow-template', id, relations: ['createdBy'] });
  }
  public async getEventWorkflowTemplatesByTriggerkey(triggerKey: string) {
    return new Entities().get({ entityName: 'event-workflow-template', criteria: { triggerKey } });
  }
  public async createEventWorkflowTemplate({
    name,
    triggerKey,
    identifier,
    isActive,
    supportingAppId,
    workflowDefinition,
    messageGroupId,
    parallelWorkerCount,
  }) {
    return new Entities().create({
      entityName: 'event-workflow-template',
      object: {
        name,
        triggerKey,
        identifier,
        isActive,
        supportingAppId,
        workflowDefinition,
        messageGroupId,
        parallelWorkerCount,
      },
    });
  }
  public async deleteEventWorkflowTemplate(eventWorkflowTemplate: EventWorkflowTemplate) {
    await new Entities().delete({ entityName: 'event-workflow-template', id: eventWorkflowTemplate?.id });
    return eventWorkflowTemplate;
  }
  public async deleteEventWorkflowTemplates(eventWorkflowTemplateIds: string[]) {
    await new Entities().batchDelete({ entityName: 'event-workflow-template', ids: eventWorkflowTemplateIds });
    return eventWorkflowTemplateIds;
  }
  public async updateEventWorkflowTemplate(id: string, changes: EventWorkflowTemplate) {
    return new Entities().update({ entityName: 'event-workflow-template', id, object: changes });
  }
  public async updateEventWorkflowTemplates(data) {
    return new Entities().batchUpdate({ entityName: 'event-workflow-template', objects: data });
  }
  public async getTemplateActionLinksByTemplateId(templateId) {
    return new Entities().get({ entityName: 'event-workflow-template-action-link', criteria: { templateId } });
  }
  public async getTemplateActionLinks() {
    return new Entities().get({ entityName: 'event-workflow-template-action-link' });
  }

  public async getTemplateActionLinksByAppId(appId: string) {
    return new Entities().get({
      entityName: 'event-workflow-template-action-link',
      criteria: { appId },
      relations: ['template', 'app'],
    });
  }
}
