import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Effect, Operation } from '@contrail/policies';
import { getUniqueId, POLICY_DETAIL_ACTION, PolicyStatement, Principal, PRINCIPAL_TYPE } from '../../types-helper';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.scss'],
})
export class PolicyDetailComponent implements OnInit, OnChanges {
  @Input() policyStatement: PolicyStatement;

  @Input() principals: Principal[];

  @Output() onDelete = new EventEmitter();

  public actions: string[] = POLICY_DETAIL_ACTION;
  public effects: string[] = Object.values(Effect);
  public prinicpalType = PRINCIPAL_TYPE;

  public actionType = Operation;

  constructor() {}

  form: FormGroup;

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.principals.currentValue && !changes.principals.firstChange) {
      const principal = {
        id: getUniqueId(),
        name: 'Everyone',
        type: PRINCIPAL_TYPE.EVERYONE,
        email: '',
        reference: '*',
      } as Principal;

      if (!changes.principals.previousValue) {
        this.principals = [principal, ...this.principals];
      }
      this.form.get('principal').setValue(this.getPrincipal());
    }
  }

  buildForm() {
    this.form = new FormGroup({
      action: this.createPolicyActionFormArray(),
      effect: new FormControl(this.policyStatement?.effect, Validators.required),
      principal: new FormControl(this.policyStatement.principal, Validators.required),
      resource: new FormControl('*'),
      id: new FormControl(this.policyStatement?.id),
    });
  }

  getPrincipal() {
    return this.principals?.find((principal) => principal?.reference === this.policyStatement?.principal);
  }

  createPolicyActionFormArray() {
    const actions = this.actions.map(
      (action: any) =>
        new FormGroup({
          name: new FormControl(action),
          checked: new FormControl(this.policyStatement?.action?.includes(action)),
        }),
    );

    return new FormArray(actions);
  }

  getPolicyDetail() {
    if (!this.form?.value) {
      return {};
    }

    const formValue = cloneDeep(this.form?.value);
    formValue.principal = formValue.principal?.reference || '';

    const selectedActions = formValue?.action
      .filter((action: { checked: boolean }) => action?.checked)
      .map((action: { name: string }) => action?.name);

    return {
      ...formValue,
      action: selectedActions,
    };
  }

  handleDelete() {
    this.onDelete.emit(this.policyStatement?.id);
  }
}
