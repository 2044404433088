import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-type-properties-list',
  templateUrl: './type-properties-list.component.html',
  styleUrls: ['./type-properties-list.component.scss'],
})
export class TypePropertiesListComponent implements OnDestroy {
  @Input() properties: Array<TypeProperty>;
  @Input() selectable: boolean;
  @Input() showActions = true;
  @Output() propertyClicked: EventEmitter<TypeProperty> = new EventEmitter();
  @Output() propertyDelete: EventEmitter<TypeProperty> = new EventEmitter();
  constructor() {}

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {}
  unsubscribe() {}

  handleClick(property: TypeProperty) {
    this.propertyClicked.emit(property);
  }
  handleDelete(property: TypeProperty) {
    this.propertyDelete.emit(property);
  }
}
